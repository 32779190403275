//
@use '@angular/material' as mat;
// Global Mixins
//


@mixin kt-clearfix() {

    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }

    &:after {
        clear: both;
    }
}

@mixin kt-button-reset() {
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    background: none;
    outline: none !important;
    margin: 0;
    padding: 0;
}

@mixin kt-input-reset() {
    border: 0;
    background: none;
    outline: none !important;
    box-shadow: none;
}

@mixin kt-btn-reset() {
    border: 0;
    background: none;
    outline: none !important;
    box-shadow: none;
    outline: none !important;
}

@mixin kt-fix-fixed-position-lags() {
    // webkit hack for smooth font view on fixed positioned elements
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

@mixin kt-fix-animation-lags() {
    transform: translateZ(0);
    -webkit-transform-style: preserve-3d;
}

@mixin kt-attr($attr, $value, $important: '') {
    @if $value !=null {
        #{$attr}: #{$value} #{$important};
    }
}

@mixin kt-hack-ie {

    //IE10 and IE11
    @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
        @content;
    }
}

@mixin kt-hack-edge-all {
    @supports (-ms-ime-align:auto) {
        @content;
    }
}

@mixin kt-rounded {
    @if $kt-rounded==true {
        @content;
    }
}

@mixin kt-not-rounded {
    @if $kt-rounded==false {
        @content;
    }
}

// Input placeholder color
@mixin kt-input-placeholder($color) {
    &::-moz-placeholder {
        color: $color;
        opacity: 1;
    }

    // See https://github.com/twbs/bootstrap/pull/11526
    &:-ms-input-placeholder {
        color: $color;
    }

    // Internet Explorer 10+
    &::-webkit-input-placeholder {
        color: $color;
    }

    // Safari and Chrome
}

@mixin kt-hover-transition {
    transition: $kt-transition;

    &:hover {
        transition: $kt-transition;
    }
}

@mixin kt-transition {
    transition: $kt-transition;
}

// Icon Sizing
// SASS Map:  (lineawesome: 1.1rem, fontawesome: 1.2rem, flaticon: 1.1rem)
@mixin kt-icons-size($config) {

    // Lineawesome
    [class^="la-"],
    [class*=" la-"] {
        font-size: kt-get($config, lineawesome);
    }

    // Fontawesome
    [class^="fa-"],
    [class*=" fa-"] {
        font-size: kt-get($config, fontawesome);
    }

    // Flaticon
    [class^="flaticon-"],
    [class*=" flaticon-"],
    [class^="flaticon2-"],
    [class*=" flaticon2-"] {
        font-size: kt-get($config, flaticon);
        // fix icon position for RTL
        float: left;
    }
}

// Icon attr
// SASS Map:  (lineawesome: 1.1rem, fontawesome: 1.2rem, flaticon: 1.1rem)
@mixin kt-icons-style($attr, $config) {

    // lineawesome
    [class^="la-"],
    [class*=" la-"] {
        #{$attr}: kt-get($config, lineawesome);
    }

    // Fontawesome
    [class^="fa-"],
    [class*=" fa-"] {
        #{$attr}: kt-get($config, fontawesome);
    }

    // Flaticon
    [class^="flaticon-"],
    [class*=" flaticon-"],
    [class^="flaticon2-"],
    [class*=" flaticon2-"] {
        #{$attr}: kt-get($config, flaticon);
    }
}

// SASS Map:  (lineawesome: 1.1rem, fontawesome: 1.2rem, flaticon: 1.1rem)
@mixin kt-icons {

    [class^="la-"],
    [class*=" la-"],
    [class^="fa-"],
    [class*=" fa-"],
    [class^="flaticon-"],
    [class*=" flaticon-"],
    [class^="flaticon2-"],
    [class*=" flaticon2-"] {
        @content;
    }
}

// Lineawesome icon
@mixin kt-la-icon($icon) {
    font-family: "LineAwesome";
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

    &:before {
        content: "#{$icon}";
    }
}

@mixin kt-la-icon-change($icon) {
    &:before {
        content: "#{$icon}";
    }
}

@mixin kt-flaticon2-icon($icon) {
    font-family: Flaticon2;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    content: "#{$icon}";
}

// Lineawesome icon
@mixin kt-la-icon-self($icon) {
    font-family: "LineAwesome";
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    content: "#{$icon}";
}

// Close icon
@mixin kt-close-icon($self:null) {
    @if $self==true {
        @include kt-la-icon-self(kt-get($kt-action-icons, close));
    } @else {
        @include kt-la-icon(kt-get($kt-action-icons, close));
    }
}

// Arrow icon
@mixin kt-arrow-icon($dir, $self:null) {
    @if $dir==down {
        @if $self==true {
            @include kt-la-icon-self(kt-get($kt-action-icons, down));
        } @else {
            @include kt-la-icon(kt-get($kt-action-icons, down));
        }
    }

    @if $dir==up {
        @if $self==true {
            @include kt-la-icon-self(kt-get($kt-action-icons, up));
        } @else {
            @include kt-la-icon(kt-get($kt-action-icons, up));
        }
    }

    @if $dir==left {
        @if $self==true {
            @include kt-la-icon-self(kt-get($kt-action-icons, left));
        } @else {
            @include kt-la-icon(kt-get($kt-action-icons, left));
        }
    }

    @if $dir==right {
        @if $self==true {
            @include kt-la-icon-self(kt-get($kt-action-icons, right));
        } @else {
            @include kt-la-icon(kt-get($kt-action-icons, right));
        }
    }
}

// Svg icon color
@mixin kt-svg-icon-color($color) {
    g {
        [fill] {
            transition: fill 0.3s ease;
            fill: $color;
        }
    }

    &:hover {
        g {
            [fill] {
                transition: fill 0.3s ease;
            }
        }
    }
}

// Breakpoint mixins
// Layout Breakpoints
// Define the minimum and maximum dimensions at which your layout will change, adapting to different screen sizes, for use in media queries.

@mixin kt-media-below($width) {
    @media (max-width: #{kt-media-breakpoint($width)}) {
        @content;
    }
}

@mixin kt-media-above($width) {
    @media (min-width: #{kt-media-breakpoint($width) + 1px}) {
        @content;
    }
}

@mixin kt-media-range($from, $to) {
    @media (min-width: #{kt-media-breakpoint($from) + 1px}) and (max-width: #{kt-media-breakpoint($to)}) {
        @content;
    }
}

@mixin kt-minimal-desktop {
    @media (min-width: #{kt-media-breakpoint(lg) + 1px}) and (max-width: #{kt-media-breakpoint(xl)}) {
        @content;
    }
}

@mixin kt-minimal-desktop-and-below {
    @media (max-width: #{kt-media-breakpoint(xl)}) {
        @content;
    }
}

@mixin kt-desktop {
    @media (min-width: #{kt-media-breakpoint(lg) + 1px}) {
        @content;
    }
}

@mixin kt-desktop-xl {
    @media (min-width: #{kt-media-breakpoint(xl) + 1px}) {
        @content;
    }
}

@mixin kt-desktop-xxl {
    @media (min-width: #{kt-media-breakpoint(xxl) + 1px}) {
        @content;
    }
}

@mixin kt-desktop-and-tablet {
    @media (min-width: #{kt-media-breakpoint(md) + 1px}) {
        @content;
    }
}

@mixin kt-tablet {
    @media (min-width: #{kt-media-breakpoint(md) + 1px}) and (max-width: #{kt-media-breakpoint(lg)}) {
        @content;
    }
}

@mixin kt-tablet-and-mobile {
    @media (max-width: #{kt-media-breakpoint(lg)}) {
        @content;
    }
}

@mixin kt-mobile {
    @media (max-width: #{kt-media-breakpoint(md)}) {
        @content;
    }
}

@mixin kt-mobile-sm {
    @media (max-width: #{kt-media-breakpoint(sm)}) {
        @content;
    }
}

@mixin kt-responsive-below($width) {
    @media (max-width: #{$width}) {
        @content;
    }
}

@mixin kt-responsive-above($width) {
    @media (min-width: #{$width}) {
        @content;
    }
}

@mixin kt-responsive-range($from, $to) {
    @media (min-width: #{$from}) and (max-width: #{$to}) {
        @content;
    }
}

@mixin ng-select-theme($theme) {

    mat-form-field.ng-select.mat-form-field-invalid,
    mat-form-field.ng-select.ng-invalid {
        .mat-form-field-prefix {
            top: 1em !important;
        }
    }

    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $isdark: map-get($theme, is-dark);

    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);


    $highlight-color: if($isdark, mat.get-color-from-palette($foreground, text), galvin-colors(main-color));

    .mat-form-field-infix {

        & > .ng-select {
            padding-bottom: 0;

            & > .ng-select-container {
                min-height: 0;

                &::after {
                    display: none;
                }
            }

            &,
            & > .ng-select-container,
            & > .ng-select-container > .ng-value-container > .ng-input > input {
                color: mat.get-color-from-palette($foreground, text) !important;
                font: inherit;
                font-family: inherit;
            }

            & > .ng-select-container > .ng-value-container {
                border-top: 0;

                & > .ng-placeholder {
                    display: none;
                    color: mat.get-color-from-palette($foreground, text, .54) !important;
                }
            }

            & > .ng-select-container > .ng-clear-wrapper,
            & > .ng-select-container > .ng-arrow-wrapper {
                bottom: 0;

                &:not(.ng-clear-wrapper) {
                    bottom: .3em;
                }
            }

        }

        & > .ng-select-focused > .ng-select-container {

            & > .ng-value-container > .ng-placeholder {
                display: inline-block;
            }

            &.ng-has-value > .ng-value-container,
            & > .ng-value-container {
                padding: 0;
                border-top: 0;
                height: auto;

                & > .ng-placeholder {

                    display: none;
                }
            }
        }

        &.ng-select > .ng-select-container > .ng-clear-wrapper,
        &.ng-select > .ng-select-container > .ng-arrow-wrapper {
            bottom: 0;
            height: auto;
            color: mat.get-color-from-palette($foreground, text, .4);
        }

        &.ng-select > .ng-select-container > .ng-clear-wrapper:hover,
        &.ng-select > .ng-select-container > .ng-arrow-wrapper:hover {
            color: mat.get-color-from-palette($foreground, text);
        }

        & > .ng-select .ng-arrow-wrapper .ng-arrow {
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid;
            height: 7px !important;
        }

        & > .ng-select.ng-select-single .ng-select-container .ng-value-container {
            height: auto;
            padding: 0;

            & > .ng-input {
                bottom: .2em;
            }
        }

        & > .ng-select.ng-select-multiple {
            margin-bottom: -4px;

            & > .ng-select-container > .ng-value-container > .ng-value {
                // WIP
                color: mat.get-color-from-palette($primary, default-contrast);
                background: galvin-colors(main-color);
                padding: 4px 8px;
                border-radius: 12px;
                margin: 0 2px 2px 0;

                .ng-value-label {
                    margin-left: 0px;
                }

                .ng-value-icon {
                    color: #fff;
                }

                .ng-value-icon:hover {
                    color: mat.get-color-from-palette($accent, default-contrast);
                }
            }

            & > .ng-select-container > .ng-clear-wrapper {
                align-self: center;
            }

            & > .ng-select-container > .ng-clear-wrapper,
            & > .ng-select-container > .ng-arrow-wrapper {
                bottom: 0;

                &:not(.ng-clear-wrapper) {
                    bottom: .3em;
                }
            }
        }

        & > .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
            padding-top: 4px;
        }

        .ng-dropdown-panel {
            @include mat.elevation(4);
            background: mat.get-color-from-palette($background, card);
            color: mat.get-color-from-palette($foreground, text) !important;


            .mat-option.ng-option-selected:not(.ng-option-marked):not(:hover) {
                background: galvin-colors(main-color);

                &:not(.ng-option-disabled) {
                    color: mat.get-color-from-palette($foreground, text);
                }
            }

            // left: 0;
            &.ng-select-bottom {
                top: calc(100% + .5em);
            }

            &.ng-select-top {
                bottom: calc(100% + 1.5em);
            }

            &.multiple {
                .ng-option {
                    &.selected {
                        background: galvin-colors(main-color);
                    }

                    &.marked {
                        background: mat.get-color-from-palette($foreground, text, .04);
                    }
                }
            }

            .ng-dropdown-header {
                border-bottom: 1px solid mat.get-color-from-palette($foreground, text, .12);
                padding: 0 16px;
                line-height: 3em;
                min-height: 3em;
            }

            .ng-dropdown-footer {
                border-top: 1px solid mat.get-color-from-palette($foreground, text, .12);
                padding: 0 16px;
                line-height: 3em;
                min-height: 3em;
            }

            .ng-dropdown-panel-items {
                .ng-optgroup {
                    user-select: none;
                    cursor: pointer;
                    line-height: 3em;
                    height: 3em;
                    padding: 0 16px;
                    color: mat.get-color-from-palette($foreground, text);
                    font-weight: 500;

                    &.ng-option-marked {
                        background: mat.get-color-from-palette($foreground, text, .04);
                    }

                    &.ng-option-disabled {
                        cursor: default;
                    }

                    &.ng-option-selected {
                        background: mat.get-color-from-palette($foreground, text, .12);
                        color: $highlight-color;
                    }
                }

                .ng-option {
                    line-height: 3em;
                    min-height: 3em;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding: 0 16px;
                    text-decoration: none;
                    position: relative;
                    color: mat.get-color-from-palette($foreground, text, .87);
                    text-align: left;

                    &.ng-option-marked {
                        background: mat.get-color-from-palette($foreground, text, .04);
                        color: mat.get-color-from-palette($foreground, text, .87);
                    }

                    &.ng-option-selected {
                        background: mat.get-color-from-palette($foreground, text, .12);
                        color: $highlight-color;
                    }

                    &.ng-option-disabled {
                        color: mat.get-color-from-palette($foreground, text, 0.38);
                    }

                    &.ng-option-child {
                        padding-left: 32px;
                    }

                    .ng-tag-label {
                        padding-right: 5px;
                        font-size: 80%;
                        font-weight: 400;
                        color: mat.get-color-from-palette($foreground, text, 0.38);
                    }
                }
            }
        }
    }

    .ng-dropdown-panel {
        @include mat.elevation(4);
        background: mat.get-color-from-palette($background, card);
        color: mat.get-color-from-palette($foreground, text) !important;


        .mat-option.ng-option-selected:not(.ng-option-marked):not(:hover) {
            background: galvin-colors(main-color);

            &:not(.ng-option-disabled) {
                color: mat.get-color-from-palette($foreground, text);
            }
        }

        // left: 0;
        &.ng-select-bottom {
            top: calc(100% + .5em);
        }

        &.ng-select-top {
            bottom: calc(100% + 1.5em);
        }

        &.multiple {
            .ng-option {
                &.selected {
                    background: galvin-colors(main-color);
                }

                &.marked {
                    background: mat.get-color-from-palette($foreground, text, .04);
                }
            }
        }

        .ng-dropdown-header {
            border-bottom: 1px solid mat.get-color-from-palette($foreground, text, .12);
            padding: 0 16px;
            line-height: 3em;
            min-height: 3em;
        }

        .ng-dropdown-footer {
            border-top: 1px solid mat.get-color-from-palette($foreground, text, .12);
            padding: 0 16px;
            line-height: 3em;
            min-height: 3em;
        }

        .ng-dropdown-panel-items {
            .ng-optgroup {
                user-select: none;
                cursor: pointer;
                line-height: 3em;
                height: 3em;
                padding: 0 16px;
                color: mat.get-color-from-palette($foreground, text);
                font-weight: 500;

                &.ng-option-marked {
                    background: mat.get-color-from-palette($foreground, text, .04);
                }

                &.ng-option-disabled {
                    cursor: default;
                }

                &.ng-option-selected {
                    background: mat.get-color-from-palette($foreground, text, .12);
                    color: $highlight-color;
                }
            }

            .ng-option {
                line-height: 3em;
                min-height: 3em;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0 16px;
                text-decoration: none;
                position: relative;
                color: mat.get-color-from-palette($foreground, text, .87);
                text-align: left;

                &.ng-option-marked {
                    background: mat.get-color-from-palette($foreground, text, .04);
                    color: mat.get-color-from-palette($foreground, text, .87);
                }

                &.ng-option-selected {
                    background: mat.get-color-from-palette($foreground, text, .12);
                    color: $highlight-color;
                }

                &.ng-option-disabled {
                    color: mat.get-color-from-palette($foreground, text, 0.38);
                }

                &.ng-option-child {
                    padding-left: 32px;
                }

                .ng-tag-label {
                    padding-right: 5px;
                    font-size: 80%;
                    font-weight: 400;
                    color: mat.get-color-from-palette($foreground, text, 0.38);
                }
            }
        }
    }
}

// set custom width to column of table edit inline component
@mixin tableEditInlineCellWidth($identification, $width) {

    thead tr th[table-edit-inline-header-cell="#{to-lower-case($identification)}"],
    tbody tr td[table-edit-inline-row-cell="#{to-lower-case($identification)}"] {
        min-width: $width !important;
        width: $width !important;
        max-width: $width !important;
        @content
    }
}

@mixin galvinIconAttention() {
    -webkit-animation: attention-animate 2s cubic-bezier(0.18, 0.89, 0.32, 1.28);;
    animation: attention-animate 2s cubic-bezier(0.18, 0.89, 0.32, 1.28);;
}

@-webkit-keyframes attention-animate {
    0% {
        transform: scale(0.5);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes attention-animate {
    0% {
        transform: scale(0.5);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

@mixin matIconSize($icon-size) {
    .mat-icon {
        font-size: $icon-size !important;
        line-height: $icon-size;
        width: $icon-size;
        height: $icon-size;
        @content
    }
}