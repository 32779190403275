/* You can add global styles to this file, and also import other style files */
@import 'sass/style.angular';

$table-scroll-bar-radius: 5px;

@font-face {
    font-family: 'Product Sans';
    font-style: normal;
    src: local('Open Sans'), local('OpenSans'),
        url(assets/galvin/fonts/font-product-sans-pro.woff2) format('woff2');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 200;
    src: url(assets/galvin/fonts/SourceSansPro-ExtraLight.ttf) format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 200;
    src: url(assets/galvin/fonts/SourceSansPro-ExtraLightItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: url(assets/galvin/fonts/SourceSansPro-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 300;
    src: url(assets/galvin/fonts/SourceSansPro-LightItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url(assets/galvin/fonts/SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    src: url(assets/galvin/fonts/SourceSansPro-Italic.ttf) format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 500;
    src: url(assets/galvin/fonts/SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 500;
    src: url(assets/galvin/fonts/SourceSansPro-Italic.ttf) format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url(assets/galvin/fonts/SourceSansPro-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 600;
    src: url(assets/galvin/fonts/SourceSansPro-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: url(assets/galvin/fonts/SourceSansPro-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 700;
    src: url(assets/galvin/fonts/SourceSansPro-BoldItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 900;
    src: url(assets/galvin/fonts/SourceSansPro-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 900;
    src: url(assets/galvin/fonts/SourceSansPro-BlackItalic.ttf) format('truetype');
}

// header base skins
.kt-header-base-light {
    @import 'sass/global/layout/header/skins/base/light.scss';
}

.kt-header-base-dark {
    @import 'sass/global/layout/header/skins/base/dark.scss';
}

// header menu skins
.kt-header-menu-light {
    @import 'sass/global/layout/header/skins/menu/light.scss';
}

.kt-header-menu-dark {
    @import 'sass/global/layout/header/skins/menu/dark.scss';
}

// brand skins
.kt-brand-dark {
    @import 'sass/global/layout/brand/skins/dark.scss';
}

.kt-brand-light {
    @import 'sass/global/layout/brand/skins/light.scss';
}

.kt-brand-cdc {
    @import 'sass/global/layout/brand/skins/cdc.scss';
}

.kt-brand-finance {
    @import 'sass/global/layout/brand/skins/finance.scss';
}

// aside skins
.kt-aside-dark {
    @import 'sass/global/layout/aside/skins/dark.scss';
}

.kt-aside-light {
    @import 'sass/global/layout/aside/skins/light.scss';
}

.kt-aside-cdc {
    @import 'sass/global/layout/aside/skins/cdc.scss';
}

.kt-aside-finance {
    @import 'sass/global/layout/aside/skins/finance.scss';
}

@import '../node_modules/@mat-datetimepicker/core/datetimepicker/datetimepicker-theme.scss';

// Using the $theme variable from the pre-built theme you can call the theming function
@include mat-datetimepicker-theme($angular-app-theme);

/**
    Apply to all scrolls in any element the scroll default used in the table-edit-inline
**/

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px lightgray;
    border-radius: $table-scroll-bar-radius;
}

::-webkit-scrollbar-thumb {
    background-color: kt-state-color(primary);
    border-radius: $table-scroll-bar-radius;
}

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
    display: none !important;
}

.mat-table__wrapper--dashboard .mat-table {
    min-width: 600px !important;
    width: 100% !important;
}

.position-static {
    position: static;
}

.custom-dialog>mat-dialog-container {
    padding: 0;
}

.custom-menu+* .cdk-overlay-pane>div.mat-menu-panel>div.mat-menu-content {
    height: 400px;

    *::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    *::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px lightgray;
        border-radius: $table-scroll-bar-radius;
    }

    *::-webkit-scrollbar-thumb {
        background-color: blue;
        border-radius: $table-scroll-bar-radius;
    }
}

.custom-menu+* .cdk-overlay-pane>div.mat-menu-panel>div.mat-menu-content *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px lightgray;
    border-radius: $table-scroll-bar-radius;
}

.custom-menu+* .cdk-overlay-pane>div.mat-menu-panel>div.mat-menu-content *::-webkit-scrollbar-thumb {
    background-color: blue;
    border-radius: $table-scroll-bar-radius;
}

.custom-menu+* .cdk-overlay-pane>div.mat-menu-panel>div.mat-menu-content *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.configuration-new {
    color: galvin-colors(status-new);
}

.build-plan-pending-requirements,
[data-value='NEW'] {
    color: galvin-colors(status-new);
}

.build-plan-under-approval,
[data-value='REQUIREMENTS_IN_PROGRESS'] {
    color: galvin-colors(status-under-approval);
}

.build-plan-open,
[data-value='OPEN'] {
    color: galvin-colors(status-open);
}

.build-plan-building,
[data-value='BUILDING'] {
    color: galvin-colors(selected);
}

.build-plan-completed,
[data-value='COMPLETED'] {
    color: galvin-colors(status-completed);
}

.build-plan-locked,
[data-value='LOCKED'] {
    color: galvin-colors(status-locked);
}

.build-plan-canceled,
[data-value='CANCELED'] {
    color: galvin-colors(status-canceled);
}

.galvin-btn-primary {
    background-color: galvin-colors(selected) !important;
    color: #fff !important;
}

.galvin-btn-primary:hover {
    background-color: galvin-colors(selected-hover) !important;
}

.galvin-icon-primary {
    color: galvin-colors(selected) !important;
}

.galvin-btn-secondary {
    color: galvin-colors(icons) !important;
}

.galvin-btn-secondary:hover:not(:disabled) {
    color: galvin-colors(selected) !important;
}

.galvin-btn-success {
    background-color: galvin-colors(status-open) !important;
    color: #fff !important;
}

.galvin-btn-success:hover {
    background-color: galvin-colors(status-open-hover) !important;
}

.galvin-icon-danger {
    color: galvin-colors(icons) !important;
}

.galvin-icon-danger:hover {
    color: galvin-colors(status-canceled) !important;
}

.galvin-btn-danger {
    background-color: galvin-colors(status-canceled) !important;
    color: #fff !important;
}

.galvin-btn-danger:hover {
    background-color: galvin-colors(btn-hover) !important;
}

button {
    text-transform: uppercase !important;
}

button.galvin-btn-danger[disabled],
button.galvin-btn-success[disabled],
button.galvin-btn-secondary[disabled],
button.galvin-btn-primary[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

button.galvin-btn-danger[disabled]:hover,
button.galvin-btn-success[disabled]:hover,
button.galvin-btn-secondary[disabled]:hover,
button.galvin-btn-primary[disabled]:hover {
    background-color: rgba(0, 0, 0, 0.15) !important;
}

.ng-dropdown-panel.auto-generic-field {
    min-width: 200px !important;
    margin-top: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-move {
    cursor: move;
}

.cursor-copy {
    cursor: copy;
}

.r-0 {
    right: 0;
}

.l-0 {
    left: 0;
}

.t-0 {
    top: 0;
}

.b-0 {
    bottom: 0;
}

.popover-dark {
    background: #6c757d !important;
    color: #6c757d !important;
}

@keyframes fadeIn {
    from {
        opacity: 0.3;
    }

    to {
        opacity: 0.7;
    }
}

.async-loading-animation {
    font-weight: bold;
    animation: fadeIn 0.6s infinite alternate;
}

.mat-dialog-title {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.75rem;
    background-color: #f2f4f5;
    padding: 28px 33px;
    margin: -24px -24px 24px -24px !important;
    width: calc(100% + 48px);
    color: kt-get($kt-state-colors, brand, base);
}

.special-requirement-icon {
    color: galvin-colors(special-requirement);
}

.new-request-icon {
    color: galvin-colors(new-request)
}

.request-comments-icon {
    color: galvin-colors(selected);
}

.toast-message {
    font-weight: bold !important;
}

.toast-warning {
    background-color: #ffb822 !important;
}

.cursor-auto {
    cursor: auto;
}

.main-color {
    color: galvin-colors();
}

.bg-main-color {
    background-color: galvin-colors(main-color) !important;
}

.popover-fullview-cells-summary {
    padding: 2px 0;

    &>.popover-body {
        padding: 0;
    }
}

.fake-disable {
    background-color: white;
    cursor: pointer;
    color: black !important;
}

.mention-text {
    color: blue !important;
    font-weight: 600 !important;
}

.mention-text-logged-user {
    color: map-get(galvin-colors(comments), 'YELLOW') !important;
    font-weight: 600 !important;
}

.popover-mention-comment {
    max-width: 25vw;
}

.cdc-request-info-tooltip .tooltip-inner {
    max-width: 350px;
    color: white;
    background-color: #6c757d;
}

.cdc-request-info-tooltip.bs-tooltip-right .arrow::before {
    border-right-color: #6c757d !important;
}

.cdc-request-info-tooltip.bs-tooltip-left .arrow::before {
    border-left-color: #6c757d !important;
}

.cdc-request-info-tooltip.bs-tooltip-top .arrow::before {
    border-top-color: #6c757d !important;
}

.cdc-request-info-tooltip.bs-tooltip-bottom .arrow::before {
    border-bottom-color: #6c757d !important;
}

.state--cutted {
    border: 2px dashed rgba(7, 37, 92, 0.595) !important;
}

.state--selected {
    border: 1px double #2196f3;
    background-color: rgba(33, 150, 243, .2) !important;
}

.cdc-confirm-dialog {

    galvin-progress-spinner .galvin-icon-primary.mat-progress-spinner,
    .mat-spinner {
        color: cdc-colors(main) !important;

        circle {
            stroke: cdc-colors(main);
        }
    }
}

.mat-bottom-sheet-container {
    min-width: auto !important;
    justify-content: center !important
}

.mat-select-search-inner {
    margin-top: 5px;
    position: relative !important;
}

.select-dropdown {
    margin-top: 30px !important;
}

.help-tooltip {
    margin-right: 0.5vw;
    font-size: 2vh !important;
}

.filter-dropdown {
    margin-top: 35px !important;
}