//
// Aside Dark Skin
//

// Include global config
@import '../../../../config';

// Include layout config
@import '../../config';

// Include header config
@import '../../header/config';

// Include aside config
@import '../../aside/config';

// Build Aside Skin
.kt-aside {
    background-color: kt-get($kt-layout-skins, finance);

    // Scrollbar
    @include kt-perfect-scrollbar-skin(finance-colors(gray));

    // Footer
    .kt-aside__footer {
        background-color: #1f2231;

        .btn {
            @include kt-transition();
            background-color: transparent;

            i {
                @include kt-transition();
                color: finance-colors(main);
            }
        }

        .show .btn,
        .btn:hover {
            @include kt-transition();
            background-color: #191b27;

            i {
                @include kt-transition();
                color: kt-brand-color();
            }
        }
    }
}

// Build Aside Menu Skin
@include kt-menu-ver-build-skin(kt-get($kt-aside-config, menu), finance);

// Aside close button
.kt-aside-close {
    background-color: rgba(finance-colors(main), 0.2);

    i {
        color: rgba(finance-colors(main), 0.7);
    }

    &:hover {
        background-color: finance-brand-color();

        i {
            color: finance-brand-color(inverse);
        }
    }
}

.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-text, .kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text {
    font-weight: 500;
    color: finance-colors(gray);
}
