.mat-tab-header {
    background-color: galvin-colors(background-tabs) !important;
}

.mat-tab-label {
    background-color: galvin-colors(texts-tabs) !important;
}

.mat-tab-label-active {
    font-weight: 600;
    color: galvin-colors(selected) !important;
}

.mat-ink-bar {
    background-color: galvin-colors(selected) !important;
}