// Tables
.mat-table__wrapper {
    width: 100%;
    overflow-x: auto;

    mat-cell,
    mat-header-cell {
        min-height: 100%;
    }

    .mat-table {
        .mat-cell,
        .mat-footer-cell,
        .mat-header-cell {
            padding-right: 10px;
        }

        .mat-cell:last-child,
        .mat-footer-cell:last-child,
        .mat-header-cell:last-child {
            padding-right: 0;
        }

        .mat-row {
            transition: padding 0.3s ease;
        }

        .mat-row-editing {
            transition: padding 0.3s ease;
            padding: 12px 0 !important;

            .mat-form-field-fluid {
                width: 99% !important;
            }
        }
    }

    .mat-column-actions {
        .mat-column-actions-wrapper {
            display: flex;
            align-items: center;
        }

        .mat-spinner {
            margin-left: 12px;
            margin-right: 8px;
        }
    }

    .mat-table__message {
        padding: 30px !important;
        text-align: center;
        font-weight: 500;
    }

    .mat-column-checkbox {
        padding-left: 20px;

        .mat-checkbox {
            .mat-checkbox-layout {
                margin-top: 0.25rem;
                margin-bottom: 0.25rem;
            }
        }
    }

    .ps__rail-y {
        z-index: 100;
    }
}

.mat-table__wrapper .mat-table {
    width: 100%;
}

.mat-table__wrapper--dashboard .mat-table {
    min-width: 600px !important;
}

.mat-table__wrapper .mat-table .email-cell {
    word-wrap: normal;
    width: 180px;
    flex: auto;
}

.mat-table__wrapper--sub .mat-table {
    min-width: 400px;

    .mat-cell:last-child {
        flex: 0 0 100px;
    }

    .mat-header-cell:last-child {
        flex: 0 0 100px;
    }
}

.mat-table-sticky {
    z-index: 97 !important;
}

.mat-table__bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.mat-column-checkbox {
    flex: 0 0 68px;
}

.mat-column-id {
    flex: 0 0 68px;
}

.mat-column-main {
    flex: 0 0 568px;
}

.mat-column-actions {
    flex: 0 0 auto;
    justify-content: flex-end;
}

.mat-cell > span {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    touch-action: auto !important;
    user-select: initial !important;
    -webkit-user-drag: initial !important;
}

.mat-column-enabled {
    flex: 0 0 90px;
    padding: 12px;
}

.mat-column-required,
.mat-column-manager {
    flex: 0 0 120px;
    padding: 12px;
}

.mat-column-vinCode {
    flex: 0 0 170px;
}

.mat-table__wrapper .mat-table .mat-row-editing.mat-row-inactive {
    height: 0;
    min-height: 0;
    padding: 0 !important;
    border-bottom: 0 !important;
}
