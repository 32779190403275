//
// Global Config
//

// Init global functions and mixins
@import 'global/init';

// Layout Breakpoints(bootstrap responsive breakpoints)
// Define the minimum and maximum dimensions at which your layout will change, adapting to different screen sizes, for use in media queries.
$kt-media-breakpoints: (
    // Extra small screen / phone
    xs: 0,
    // Small screen / phone
    sm: 576px,
    // Medium screen / tablet
    md: 768px,
    // Large screen / desktop
    lg: 1024px,
    // Extra large screen / wide desktop
    xl: 1399px) !default;

// Global rounded border mode
$kt-rounded: true !default;

// Border Radius
$kt-border-radius: 4px !default;

// Core icon codes(lineawesome icons: https://icons8.com/line-awesome)
$kt-action-icons: (
    close: '\f191',
    down: '\f110',
    up: '\f113',
    left: '\f111',
    right: '\f112',
    plus: '\f2c2',
    minus: '\f28e'
    ) !default;

// Core bold icon codes(lineawesome icons: https://keenthemes.com/metronic/preview/default/components/icons/flaticon.html)
$kt-action-bold-icons: (
    down: '\f1a3',
    up: '\f1a5',
    left: '\f1a4',
    right: '\f19d',
    close: '\f1b2'
    ) !default;

// Elevate shadow
$kt-elevate-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05) !default;
$kt-elevate-shadow-2: 0px 0px 13px 0px rgba(82, 63, 105, 0.1) !default;

// Dropdown shadow
$kt-dropdown-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !default;

// Custom scrollbar color
$kt-scroll-color: darken(#ebedf2, 6%) !default;

// Transition
$kt-transition: all 0.3s !default;

// Modal Z-index
$kt-modal-zindex: 1050 !default;

// dropdown Z-index
$kt-dropdown-zindex: 495 !default;

// State Color
$kt-state-colors: (
    // Metronic states
    brand: (base: #638ab1,
        inverse: #ffffff),
    brand-cdc: (base: #279b88,
        inverse: #ffffff),
    light: (base: #ffffff,
        inverse: #282a3c),
    dark: (base: #282a3c,
        inverse: #ffffff),
    brand-finance: (base: #004373,
        inverse: #ffffff),
    // Bootstrap states
    primary: (base: #638ab1,
        inverse: #ffffff),
    success: (base: #009f00,
        inverse: #ffffff),
    info: (base: #0080ff,
        inverse: #ffffff),
    warning: (base: #ffb822,
        inverse: #111111),
    danger: (base: #d90000,
        inverse: #ffffff)) !default;

// Base colors
$kt-base-colors: (
    label: (1: #a2a5b9,
        2: #74788d,
        3: #595d6e,
        4: #48465b),
    shape: (1: #f0f3ff,
        2: #e8ecfa,
        3: #93a2dd,
        4: #646c9a),
    grey: (1: #f7f8fa,
        2: #ebedf2,
        3: darken(#ebedf2, 3%),
        4: darken(#ebedf2, 6%))) !default;

// Social network colors(see: https://brandcolors.net/)
$kt-social-colors: (
    facebook: (base: #3b5998,
        inverse: #ffffff),
    google: (base: #dc4e41,
        inverse: #ffffff),
    twitter: (base: #4ab3f4,
        inverse: #ffffff),
    instagram: (base: #517fa4,
        inverse: #ffffff),
    youtube: (base: #b31217,
        inverse: #ffffff),
    linkedin: (base: #0077b5,
        inverse: #ffffff),
    skype: (base: #00aff0,
        inverse: #ffffff)) !default;

// Root Font Settings
$kt-font-families: (
    regular: unquote('Source Sans Pro, Helvetica, sans-serif'),
    heading: unquote('Source Sans Pro, Helvetica, sans-serif')) !default;

// Root Font Settings
$kt-font-size: (
    size: (desktop: 13px,
        tablet: 12px,
        mobile: 12px),
    weight: 300) !default;

// General Link Settings
$kt-font-color: (
    text: #646c9a,
    link: (default: kt-state-color(brand),
        hover: darken(kt-state-color(brand), 6%))) !default;

// Portlet settings
$kt-portlet: (
    min-height: (default: (desktop: 60px,
            mobile: 50px),
        sm: (desktop: 50px,
            mobile: 40px),
        lg: (desktop: 80px,
            mobile: 60px),
        xl: (desktop: 100px,
            mobile: 80px)),
    space: (desktop: 25px,
        mobile: 15px),
    bottom-space: (desktop: 20px,
        mobile: 20px),
    border-color: kt-base-color(grey, 2),
    bg-color: #f2f4f5,
    shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05)) !default;

// Page padding
$kt-page-padding: (
    desktop: 25px,
    mobile: 15px) !default;

// Page container width
$kt-page-container-width: 1380px !default;

// Custom Scroll(Perfect Scrollbar) size
$kt-custom-scroll-size: 4px !default;

$galvin-colors: (
    main-color: #638ab1,
    banner: #27639b,
    title: #1c336d,
    title-light: #e8ebf0,
    selected: #0080ff,
    selected-hover: #1ca7fc,
    icons: #c8cad1,
    kt-menu__ver-arrow background-tabs: #f8f8f8,
    texts-tabs: #4d4f5c99,
    header-table: #4d4f5c0a,
    texts: #4d4f5c,
    texts-disabled: #bababf,
    option-selected: #e5e5e6,
    status-new: #ff7600,
    status-under-approval: #d6871b,
    status-open: #009f00,
    new-request: #009f00,
    status-open-hover: #1bb71e,
    status-completed: #03b1ff,
    status-locked: #9d9d9d,
    status-detached: #9d9d9d,
    status-canceled: #d90000,
    canceled-proto-color: #f7dfdf,
    btn-hover: #f40c1a,
    status-locking-soon: #de2727,
    status-already-requested: #3ec1fd,
    status-closed: #616161,
    card-field-label: #a3a6b4,
    status-mass-cancel: gray,
    status-mass-cancel-hover:rgb(158, 158, 158),

    hw-revision: ('BOARD': #3d7a39,
        'EVB': #1f4d9b,
        'EVB2': #bc6c25,
        'EVB3': #219ebc,
        'EVT': #7832be,
        'EVT2': #6b705c,
        'EVT3': #f4a259,
        'DVT': #1faf92,
        'DVT1': #ad2470,
        'DVT1A': #e36414,
        'DVT1B': #940f22,
        'DVT1C': #e63946,
        'DVT2': #868249,
        'DVT2A': #b37037,
        'DVT2B': #c9184a,
        'DVT2C': #e07a5f,
        'DVT3': #01497c,
        'DVT3A': #5f0f40,
        'DVT3B': #ff8fab,
        'DVT3C': #735d78,
        'PVT': #0078c8,
        'OTHER': #5f5f5f),
    accessory-type: ('CHARGER': #3d7a39,
        'CABLE': #1f4d9b,
        'PROTECTION': #7832be,
        'SIM': #1faf92,
        'HEADSET': #ad2470,
        'DANGLE': #94565f,
        'RF': #940f22,
        'BATTERY': #868249,
        'OTHER': #4d4f5c,
        'PACKAGE': #b37037),
    button-disabled: #d8d8d8,
    button-border: #4d4f5c33,
    text-disabled: #afafaf,
    request-increase: #4ad991,
    request-decrease: #ff7285,
    tag-gray: #eff2f6,
    request-equal: #4d4f5c,
    special-requirement: #ff7600,
    source: (1: #a8e6cf,
        // Teal
        2: #90d2e8,
        // Cotton
        3: #cebae8,
        // Lavender
        4: #deb1a4,
        // Peach
        5: #f3ebc9 // Cream
    ),
    cmfColors: ('Black': #000000,
        'Gold': #ffc24e,
        'Gondwana': #6f8030,
        'Jasper': #a32514,
        'Jazz Blue': #0007c3,
        'White': #ffffff,
        'Stardust': #003632,
        'Cosmos': #321051),
    dsr-colors: ('YELLOW': #f2eda2,
        'BLUE': #6ca9d3,
        'RED': #ff857f,
        'GREEN': #d8f0d3,
        'GRAY': rgba(0, 0, 0, 0.2)),
    cdc-track-colors: ('YELLOW': #f2eda2,
        'BLUE': #6ca9d3,
        'RED': #ff857f,
        'GREEN': #d8f0d3,
        'GRAY': rgba(0, 0, 0, 0.2),
        'FOCUSED_YELLOW':#bab687d8,
        'FOCUSED_BLUE': #748997d9,
        'FOCUSED_RED': #a77a76d4,
        'FOCUSED_GREEN': #73876fad,
        'FOCUSED_GRAY': rgba(0, 0, 0, 0.127),
    ),
    table-header: #ebeff2,
    business-type-colors: (#f4f5bd,
        #efc8a8,
        #ccf9db,
        #c5c2e7,
        #cde5f7),
    comments: ('BLUE': #0000ff,
        'BLACK': #000000,
        'YELLOW': #ffbb00,
    ),
);

// CDC Colors
$cdc-colors: (
    main: #279b88,
    main-light: #06a2ab,
    main-dark: #0d6a71,
    main-header: #aad3cf,
    main-hover: #9ac0bc,
    highlight: #279b881c,
    danger: #DA3C1F,
    alert: #06c52f,
    title: #96a1b4,
    // scan cells
    valid-cell: #8bb98a50,
    invalid-cell: #fa8b9c30,
    repeated-cell: #faf74870,
    // scan cells dark
    valid-cell-dark: #8bb98a80,
    invalid-cell-dark: #fa8b9c60,
    repeated-cell-dark: #faf74890,
    // Same as main but with 11% opacity (1C)
    gray: #92a1b6,
    pink-primary: #EAD1DC,
    pink-hover: #dbbac8,
    pink-dark: #d88eae,
    blue-primary: #cfe2f3,
    blue-hover: #9bbfdf,
    blue-dark: #48769e,) !default;

// Finance Colors
$finance-colors: (
    main: #004373,
    gold-main: #ffb325,
    gold-dark: #ffa500,
    highlight: #27639b1c,
    gray: #92a1b6) !default;

$fx-sizes: 10 14 16 20 24 32;

@each $size in $fx-sizes {
    .fx-#{$size} {
        font-size: #{$size}px !important;
    }
}