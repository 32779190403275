.galvin-icon-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
}

@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.galvin-icon-rotate-90 {
    filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.galvin-icon-rotate-180 {
    filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.galvin-icon-rotate-270 {
    filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=3);
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}

.galvin-icon-flip-horizontal {
    filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

.galvin-icon-flip-vertical {
    filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
    -webkit-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
}

:root .galvin-icon-rotate-90,
:root .galvin-icon-rotate-180,
:root .galvin-icon-rotate-270,
:root .galvin-icon-flip-horizontal,
:root .galvin-icon-flip-vertical {
    filter: none;
}

.galvin-icon-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle;
}

.galvin-icon-stack-1x,
.galvin-icon-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
}

.galvin-icon-inverse {
    color: #ffffff;
}

.galvin-icon-menu-permission:before {
    content: url(./icons/icon_menu_permission.svg);
}

.galvin-icon-menu-notification:before {
    content: url(./icons/notifications_bell.svg);
}

.galvin-icon-menu-home:before {
    content: url(./icons/icon_menu_home.svg);
}

.galvin-icon-team-selector:before {
    content: url(./icons/team_select.svg);
}

.galvin-icon-sub-team-selector:before {
    content: url(./icons/sub-item.svg);
}

.galvin-icon-team-history:before {
    content: url(./icons/team_history.svg);
}

.galvin-icon-configuration-history:before {
    content: url(./icons/configuration_history.svg);
}

.galvin-icon-special-requirements-history:before {
    content: url(./icons/special_requirements_history.svg);
}

.galvin-icon-menu-build-plan:before {
    content: url(./icons/icon_menu_bp.svg);
}

.galvin-icon-build-plan-logo-full-c:before {
    content: url(./icons/icon_bp_logo_full_c.svg);
}

.galvin-icon-build-plan-logo:before {
    content: url(./icons/icon_bp_logo.svg);
}

.galvin-icon-cdc-logo-full-c:before {
    content: url(./icons/icon_cdc_logo_full_c.svg);
}

.galvin-icon-cdc-logo:before {
    content: url(./icons/icon_cdc_logo.svg);
}

.galvin-icon-finance-logo-full-c:before {
    content: url(./icons/icon_finance_logo_full_c.png);
    ;
}

.galvin-icon-finance-logo:before {
    content: url(./icons/Coin-yellow-and-white-small.png);
}

.galvin-icon-finance-logo {
    padding-top: 6px;
}

.galvin-icon-menu-cdc-home:before {
    content: url(./icons/icon_menu_home_cdc_gray.svg);
}

.galvin-icon-menu-cdc-home-focus:before {
    content: url(./icons/icon_menu_home_cdc_focus.svg);
}

.galvin-icon-menu-sorting:before {
    content: url(./icons/icon_menu_sorting_assign_gray.svg)
}

.galvin-icon-menu-sorting-focus:before {
    content: url(./icons/icon_menu_sorting_assign_focus.svg)
}

.galvin-icon-menu-shipment:before {
    content: url(./icons/icon_menu_shipment_gray.svg)
}

.galvin-icon-menu-shipment-focus:before {
    content: url(./icons/icon_menu_shipment_focus.svg)
}

.galvin-icon-menu-po-quotation:before {
    content: url(./icons/po_quotation_icon.svg)
}

.galvin-logo:before {
    content: url(./icons/galvin_logo.svg);
}

.galvin-icon-fields-values:before {
    content: url(./icons/icon_fields_values.svg);
}

.galvin-icon-table-view:before {
    content: url(./icons/table_view.svg);
}

.galvin-icon-analytics:before {
    content: url(./icons/analytics.svg);
}

.galvin-icon-shield-lock:before {
    content: url(./icons/shield_lock.svg);
}

.galvin-icon-menu-groups::before {
    content: url(./icons/icon_menu_groups.svg);
}

.galvin-icon-menu-scopes::before {
    content: url(./icons/icon_menu_scopes.svg);
}

.galvin-icon-menu-teams::before {
    content: url(./icons/icon_menu_teams.svg);
}

.galvin-icon-menu-prototype-request::before {
    content: url(./icons/icon_menu_prototype_request.svg);
}

.galvin-icon-menu-accessory-request::before {
    content: url(./icons/icon_menu_accessory_request.svg);
}

.galvin-icon-phone-large::before {
    content: url(./icons/phone_icon_large.svg);
}

.galvin-icon-phone::before {
    content: url(./icons/phone_icon.svg);
}

.galvin-icon-edit::before {
    content: url(./icons/edit.svg);
}

.galvin-icon-save::before {
    content: url(./icons/save.svg);
}

.galvin-icon-save-small::before {
    content: url(./icons/save_small.svg);
}

.galvin-icon-add-circle::before {
    content: url(./icons/add_circle.svg);
}

.galvin-icon-information::before {
    content: url(./icons/information.svg);
}

.galvin-icon-up::before {
    content: url(./icons/north-16px.svg);
}

.galvin-icon-down::before {
    content: url(./icons/south-16px.svg);
}

.galvin-icon-back::before {
    content: url(./icons/arrow_back_ios-24px.svg);
}

.galvin-icon-forward::before {
    content: url(./icons/arrow_forward_ios-24px.svg);
}

.galvin-icon-user-management::before {
    content: url(./icons/icon_user_management.svg);
}

.galvin-icon-check::before {
    content: url(./icons/icon-check.svg);
}

.galvin-icon-close::before {
    content: url(./icons/icon-close.svg);
}

.galvin-icon-secure::before {
    content: url(./icons/icon_secure.svg);
}

.galvin-icon-no-marking::before {
    content: url(./icons/icon_no_marking.svg);
}

.galvin-icon-reports::before {
    content: url(./icons/icon_menu_reports.svg);
}

.galvin-icon-faq::before {
    content: url(./icons/icon_menu_faq.svg);
}

.galvin-home-work::before {
    content: url(./icons/home_work_white.svg);
}

.galvin-icon-filter::before {
    content: url(./icons/filter.svg);
}

.galvin-icon-filter-clear::before {
    content: url(./icons/filter_clear.svg);
}

.galvin-bg-selected {
    background-color: #0080FF;
}

.galvin-semi-bold {
    font-weight: 600;
}

.galvin-action-icon {
    color: #4D4F5C66;
}

.fi {
    transform: scale(1.3);
}

.material-icons-center {
    vertical-align: middle;
    padding-bottom: 3px;
}