//
// Layout Config
//
// Layout
$kt-aside-default-width: 220px;
$kt-aside-minimize-width: 0px;
$kt-aside-offcanvas-width: 255px;
// Layout Skins
$kt-layout-skins: (
  dark: #638ab1,
  cdc: #ffffff,
  light: #ffffff,
  finance: #ffffff
);
// content bg color
$kt-content-bg-color: #f2f4f5;
// Border Radius
$kt-border-radius: 4px;
// Page padding
$kt-page-padding: (
  desktop: 25px,
  mobile: 15px,
);
// Page container width
$kt-page-container-width: 1380px;
